import { UserCoverStyle } from "authory-api-types/dist/enums";
import { AuthenticatedUserResponse, UserResponse } from "authory-api-types/dist/types";
import { DEFAULT_OG_IMAGE } from "../types/defaultOgImage";
import { getV3ProfileCollections } from "./getV3ProfileCollections";

export const getProfileDisplayImageUrl = (user: AuthenticatedUserResponse | UserResponse) => {
    const isUnplashOrImage = user.coverStyle === UserCoverStyle.image || user.coverStyle === UserCoverStyle.unsplash;
    if (isUnplashOrImage && user.coverUrl.length) return user.coverUrl[0].url;
}

export const getProfileOGDisplayImageUrl = (user: AuthenticatedUserResponse | UserResponse) => {
    const isUnplashOrImage = user.ogCoverStyle === UserCoverStyle.image || user.ogCoverStyle === UserCoverStyle.unsplash;
    if (isUnplashOrImage && user.ogCoverUrl.length) return user.ogCoverUrl[0].url;

    if (user.ogImageUrl !== null) return user.ogImageUrl;

    const { firstCollection } = getV3ProfileCollections(user.collections);

    if (firstCollection) {
        return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/preview/collection?user=${user.slug}&collection=${firstCollection.canonicalSlug}&cachebust=${Math.random().toString(36).substring(10)}`;
    }

    return DEFAULT_OG_IMAGE;
}