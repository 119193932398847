import React from "react";
import styled from "styled-components";
import { V3Footer } from "../V3Footer";

const V3ContentPublicSizer = styled.div`
    min-height: calc(100vh);
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

const V3PublicFooterWrapper = styled.div`
    padding: 0 20px;

    @media print {
        display: none;
    }
`;

interface V3PublicSizerProps {
    isPrivateView: boolean,
    verticalSpace?: boolean,
    showMarketingLinks?: boolean,
    signupSkeleton?: boolean,
}


export const V3PublicSizer: React.FC<React.PropsWithChildren<V3PublicSizerProps>> = ({ children, isPrivateView, verticalSpace, showMarketingLinks = true, signupSkeleton = false, }) => {
    const SizerToUse = isPrivateView ? React.Fragment : V3ContentPublicSizer;

    return <SizerToUse>
        <div>
            {children}
        </div>
        <V3PublicFooterWrapper>
            {
                !isPrivateView && !signupSkeleton && <V3Footer maxWidth="984px" verticalSpace={verticalSpace} showMarketingLinks={showMarketingLinks} />
            }
        </V3PublicFooterWrapper>
    </SizerToUse>
}