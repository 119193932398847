import { UserCoverStyle } from "authory-api-types/dist/enums";
import { Collection } from "authory-api-types/dist/types";
import { DEFAULT_OG_IMAGE } from "../types/defaultOgImage";

export const getCollectionDisplayImageUrl = (collection: Collection) => {
    const isUnplashOrImage = collection.coverStyle === UserCoverStyle.image || collection.coverStyle === UserCoverStyle.unsplash;
    if (isUnplashOrImage && collection.coverImage.length) return collection.coverImage[0].url;

    if (collection.coverStyle === UserCoverStyle.color) {
        return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/preview/color?color=${collection.coverColor?.replace("#", "")}`;
    }
}

export const getCollectionOGDisplayImageUrl = (collection: Collection, userSlug: string) => {
    const isUnplashOrImage = collection.ogCoverStyle === UserCoverStyle.image || collection.ogCoverStyle === UserCoverStyle.unsplash;
    if (isUnplashOrImage && collection.ogCoverImage.length) return collection.ogCoverImage[0].url;

    const coverImage = getCollectionDisplayImageUrl(collection);
    if (coverImage) return coverImage;

    if (collection.articleCount > 0) {
        return `${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/preview/collection?user=${userSlug}&collection=${collection.canonicalSlug}&cachebust=${Math.random().toString(36).substring(10)}`;
    }

    return DEFAULT_OG_IMAGE;
}