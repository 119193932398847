import { UserCoverStyle } from "authory-api-types/dist/enums";
import { V3ProfileHeroColorVariants } from "./V3ProfileHero.types";

const isHexLight = (color: string) => {
    const hex = color.replace('#', '').replace("White", "FFFFFF");
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155;
}

export const getV4ColorVariant = (color?: string, coverStyle?: UserCoverStyle) => {
    if (coverStyle === UserCoverStyle.none) return V3ProfileHeroColorVariants.dark;
    if (coverStyle === UserCoverStyle.color && !color) return V3ProfileHeroColorVariants.dark;
    if (coverStyle === UserCoverStyle.color && color && isHexLight(color)) return V3ProfileHeroColorVariants.dark;
    return V3ProfileHeroColorVariants.light;
}

export const SORTABLE_HOME_IDENTIFIER = "SORTABLE_HOME_IDENTIFIER";