import { UserCoverStyle } from "authory-api-types/dist/enums";
import { AuthenticatedUserResponse, Collection, UserResponse } from "authory-api-types/dist/types";

export const getCoverInfo = (item: AuthenticatedUserResponse | UserResponse | Collection | undefined) => {
    if (!item) return { color: undefined, authorUploadedImage: false }

    const color = item.coverStyle === UserCoverStyle.color && item.coverColor !== "" ? item.coverColor || undefined : undefined;
    const authorUploadedImage = !color && item.coverStyle === UserCoverStyle.image || item.coverStyle === UserCoverStyle.unsplash;

    return { color, authorUploadedImage };
}