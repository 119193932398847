import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from "yup";
import { V3Button } from '../Button';
import { V3Loader } from '../Loader';
import { RightAlignPanel } from '../ReusableStyledComponents/RightAlignPanel';
import { V3TextArea, V3TextInput } from '../TextInput/V3TextInput';
import { V3ModalBase } from "../V3ModalBase"
import { VerticalSpacing } from '../VerticalSpacing';
import { V3RecaptchaWrapper } from './V3ContactUser.styles';
import { V3Body } from '../Typography';

const startReCaptcha = () => {
    setTimeout(() => {
        try {
            //@ts-ignore
            window?.grecaptcha && window.grecaptcha.render(
                "recaptchaContact",
                {
                    sitekey: process.env.NEXT_PUBLIC_RECAPTCHA_KEY
                }
            )
        } catch { }
    }, 50);
}

export interface ContactUserFormik {
    email: string
    name: string
    message: string
    captcha: string
}

interface V3ContactUserProps {
    modelOpen: boolean,
    onCloseHandler: () => void,
    userName: string,
    onContactUserSubmit: (data: ContactUserFormik) => Promise<void>,
}

export const V3ContactUser = ({ modelOpen, onCloseHandler, userName, onContactUserSubmit }: V3ContactUserProps) => {

    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (modelOpen) startReCaptcha();
    }, [modelOpen]);

    const formik = useFormik<ContactUserFormik>({
        initialValues: {
            email: '',
            name: '',
            message: '',
            captcha: '',
        },
        onSubmit: async (data) => {
            try {
                //@ts-ignore
                const captcha = await grecaptcha.getResponse() as string;

                data.captcha = captcha;
                setSubmitting(true);
                await onContactUserSubmit(data);
                setSubmitting(false);
                setSubmitted(true);
            } catch {
                setSubmitting(false);
            }
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required("Your name is required.")
                .trim()
                .lowercase()
                .ensure()
                .max(250, "Name must be 250 characters or less"),
            email: Yup.string()
                .required("Your email address is required.")
                .email()
                .trim()
                .lowercase()
                .ensure()
                .max(250, "Email must be 250 characters or less"),
            message: Yup.string()
                .required("Your message is required.")
                .trim()
                .lowercase()
                .ensure()
                .max(2000, "Message must be 2000 characters or less")
        })
    });

    return <V3ModalBase
        isOpen={modelOpen}
        onClose={onCloseHandler}
        title={`Contact ${userName}`}
        bottomPanel={
            <RightAlignPanel>
                <div style={{ minHeight: 30 }}>
                    {submitting ? <V3Loader /> : <V3Button
                        text={submitted ? "Close" : "Send"}
                        autoWidth
                        onClick={() => {
                            if (!submitted) {
                                formik.submitForm();
                            } else {
                                onCloseHandler();
                            }
                        }}
                    />}
                </div>
            </RightAlignPanel>
        }
    >
        {
            submitted ? <>
                <div style={{ wordBreak: "break-word" }}>
                    <V3Body>Great! Your message has been sent. {userName} will be in touch shortly.</V3Body>
                </div>
            </> : <form onSubmit={formik.handleSubmit}>
                <VerticalSpacing bottom={6} top={18}>
                    <V3TextInput
                        label="Your name"
                        name="name"
                        placeholder="e.g. Jane Doe"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        error={formik.errors.hasOwnProperty("name") && formik.touched.hasOwnProperty("name") ? formik.errors.name : undefined}
                    />
                </VerticalSpacing>
                <VerticalSpacing bottom={6} top={18}>
                    <V3TextInput
                        label="Your email"
                        name="email"
                        type="email"
                        placeholder="e.g. jane.doe@gmail.com"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        error={formik.errors.hasOwnProperty("email") && formik.touched.hasOwnProperty("email") ? formik.errors.email : undefined}
                    />
                </VerticalSpacing>
                <VerticalSpacing bottom={24} top={18}>
                    <V3TextArea
                        label="Your message"
                        name="message"
                        placeholder={`e.g. Hey ${userName}, …`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                        error={formik.errors.hasOwnProperty("message") && formik.touched.hasOwnProperty("message") ? formik.errors.message : undefined}
                    />
                </VerticalSpacing>
                <V3RecaptchaWrapper id="recaptchaContact" />
            </form>
        }
    </V3ModalBase >
}